
export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },

    {
        title: 'Month',
        key: 'Month',
        sortable: true,
        minWidth: 100,
    },

	{
        title: 'Year',
        key: 'Year',
		sortable: true,
        minWidth: 100,
       
    },

    {
        title: 'Net Payable',
        key: 'NetPayable',
        sortable: true,
        minWidth: 100,
        render: (h, params) => {
        return h('span',params.row.NetPayable?parseFloat( params.row.NetPayable).toFixed(2):'');
    },
    },
    {
        title: 'Salary Release Date',
        key: 'ReleaseDate',
        sortable: true,
        minWidth: 100,
       
    },
   
   
]
