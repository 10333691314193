<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Salary History" slot="title" link="/helpContent/salaryHistory" />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-4">
          <FormSelect label="Company" :items="companyList" item-name="Name" rules="required" item-value="id"  v-model="filtering.companyId" :onChange="getCompanyStaff"/>
        </div>
        <div class="col-sm-4">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="NAME"
            item-value="id"
            v-model="filtering.staffId"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
            <Icon type="ios-search" />
          </FormButton>
        </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="salaryHistoryList"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-sort-change="sortChange">
      <template slot="actionsLeft">
          <TableButton @click="() => Release()">
              <img :src="require(`Assets/images/check-o.png`)"/>
              Release
          </TableButton>
        </template>
    </DataTable>
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmRelease"
          >Confirm
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.ok"
          @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="() =>showMessage.isVisible = false"
          >Ok</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import {getAllCompanyList, getAllstaffByCompanyId,getStaffSalaryHistory,releaseSalary} from "../api";
import tableConfig from "./table";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "SalaryHistory",
  mixins: [loadingMixin,validateSelectedRows],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      filtering: {
        companyId: "",
        staffId: "",
      },
         sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
      companyList: [],
      staffList: [],
      salaryHistoryList:[],
      tableConfig,
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
     this.getAllList();
  },
  methods: {
    async getAllList() {
    await  getAllCompanyList().then((res) => {
        this.companyList = res.data;
      });
    },
    Release() {
        if (this.validateSelectedLength(1)) {
          if (this.salaryValidate()) {
          }
        }
      },
      confirmRelease() {
        this.showWarningModal(false);
        const data = {
          staffId: this.selectedRows[0].id,
          month: this.selectedRows[0].mm,
          year: this.selectedRows[0].Year,
        };
        releaseSalary(data).then((res) => {
          if (res.data[0].status > 1) {
            this.showWarningModal(true);
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.warningModal.message = res.data[0].message;
            this.selectedRows = [];
          } else {
            this.selectedRows = [];
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          }
        });
      },
   async getCompanyStaff(e){
    const data={
      companyId:e
    }
      await  getAllstaffByCompanyId(data).then((res) => {
        this.staffList = res.data;

      });
    },

    search() {
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },

    getDataByFilter() {
      setTimeout(() => {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            this.showLoader();
            const { key, order } = this.sorting;
            const { perPage, page } = this.pagination;
            const filters = {};
            if (this.filtering.companyId) {
              filters.companyId = this.filtering.companyId;
              filters.staffId = this.filtering.staffId;
            }
            const data = {
              filterjson: {
                filter: [filters],
                sort: [
                  {
                    key,
                    order,
                  },
                ],
                 paging: [
                    {
                      startIndex: page,
                      pageSize: perPage,
                    },
                  ],
              },
            };
            getStaffSalaryHistory(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }
        });
      }, 50);
    },
    handleResponse(response) {
      this.hideLoader();
      this.salaryHistoryList = response.data;
    },
  },
};
</script>
